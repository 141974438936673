<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  font-family: PingFangSC-Regular, sans-serif;
}
#app {
}
</style>
